import React, { PureComponent } from 'react'
import { ServiceProviderList } from './../common'
import PropTypes from 'prop-types'

class BrowseFilters extends PureComponent {
    state = {
        showFilters: false,
    }

    toggleFilters = () =>
        this.setState(prevState => ({
            showFilters: !prevState.showFilters,
        }))

    render() {
        return (
            <div className={`filter-panel${this.state.showFilters ? ' show-filters' : ''}`}>
                <div
                    className="d-flex justify-content-between selected-provider"
                    style={{ backgroundColor: this.props.provider.colour }}
                >
                    <span className="provider-name">{this.props.provider.name}</span>
                    <button className="filter-toggle as-link" onClick={this.toggleFilters}>
                        Providers
                    </button>
                </div>
                <div className={'filters mt-3'}>
                    <ServiceProviderList />
                </div>
            </div>
        )
    }
}

BrowseFilters.propTypes = {
    provider: PropTypes.any,
}

BrowseFilters.defaultProps = {
    provider: {
        name: '',
        colour: '',
    },
}

export default BrowseFilters
