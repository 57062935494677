import React, { PureComponent } from 'react'
import get from 'lodash/get'
import filter from 'lodash/filter'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import find from 'lodash/find'
import PageLayout from '../components/layouts/PageLayout'
import { AZFilterList } from '../components/common'
import SearchSidebar from '../components/search/SearchSidebar'
import BrowseFilters from '../components/guideline/BrowseFilters'
import Breadcrumb from '../components/common/Breadcrumb'
import { buildURL, CONTENTFUL_GRAPHQL_TYPES, SECTIONS } from '../utils/url'

import '../assets/styles/guideline/_browseGuidelines.scss'
import * as SearchHelper from '../helpers/SearchHelper'
import IconStar from '../components/common/icons/Star'

export default class Browse extends PureComponent {
    constructor(props) {
        super(props)

        const serviceProviders = get(props, 'data.allContentfulTypeGuidelineProvider.edges').map(({ node }) => node)
        const allGuidelines = get(props, 'data.allContentfulPageGuideline.edges').map(({ node }) => node)
        const allCalculators = get(props, 'data.allContentfulWidgetCalculator.edges').map(({ node }) => {
            node.title = node.name.trim()
            node.guidelineType = {guidelineType: 'Calculator'}
            return node
        })
        const searchableCalculators = filter(allCalculators, {'searchable': true})
        let allContents = allGuidelines.concat(searchableCalculators)
        const toDelete = {slug: 'test-guideline', title: "Test - DON'T DELETE"}
        allContents = allContents.filter(allContent => allContent.slug !== toDelete.slug)
        if (typeof window !== 'undefined') {
            const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || []
            if (bookmarks.length > 0) {
                allContents = allContents.map(allContent => {
                    const bookmarkIndex = bookmarks.findIndex(bookmark => bookmark.slug === allContent.slug)
                    if (bookmarkIndex !== -1) {
                        allContent.isBookmarked = true
                    }
                    return allContent
                })
            }
        }

        this.state = {
            guidelines: allContents,
            allGuidelines: allContents,
            serviceProviders,
            serviceProvider: null,
            type: null,
            category: null,
            index: null,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const queryParams = SearchHelper.queryParamsToObject(props.location.search)

        const serviceProviderSlug = get(queryParams, 'sp')
        const index = get(queryParams, 'index')
        const type = get(queryParams, 'type')
        const category = get(queryParams, 'cat')

        const serviceProvider = serviceProviderSlug ? find(state.serviceProviders, { slug: serviceProviderSlug }) : null

        return {
            serviceProvider,
            type,
            category,
            index,
            guidelines: Browse.filterGuidelines(serviceProvider, state.allGuidelines),
        }
    }

    onItemHovered = (item, isHovered) => {
        const guidelines = this.state.guidelines
        const hoverIndex = guidelines.findIndex(guideline => guideline.slug === item.slug)
        guidelines[hoverIndex].isHovered = isHovered
        this.setState({ guidelines })
    }

    onStarHovered = (item, isStarHovered) => {
        const guidelines = this.state.guidelines
        const hoverIndex = guidelines.findIndex(guideline => guideline.slug === item.slug)
        guidelines[hoverIndex].isStarHovered = isStarHovered
        this.setState({ guidelines })
    }

    onBookmark = (item) => {
        let bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || []
        const guidelines = this.state.guidelines
        const bookmarkIndex = bookmarks.findIndex(bookmark => bookmark.slug === item.slug)
        const index = guidelines.findIndex(guideline => guideline.slug === item.slug)
        guidelines[index].isBookmarked = !guidelines[index].isBookmarked
        this.setState({ guidelines })
        if (bookmarkIndex === -1) {
            localStorage.setItem('bookmarks', JSON.stringify([...bookmarks, item]))
        } else {
            bookmarks.splice(bookmarkIndex, 1)
            localStorage.setItem('bookmarks', JSON.stringify(bookmarks))
        }  
    }

    static filterGuidelines = (serviceProvider, allGuidelines) => {
        return allGuidelines.filter(guideline => {
            if (serviceProvider) {
                return guideline.serviceProvider && guideline.serviceProvider.slug === serviceProvider.slug
            }

            return true
        })
    }

    render() {
        const { guidelines, serviceProvider, type, category, index } = this.state
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)

        return (
            <PageLayout className="guideline-list" hideSocials sitemap={sitemap} activeSection={SECTIONS.HEALTH_PROFESSIONALS.key}>
                <Helmet title="Starship - Clinical Guidelines" />
                <Breadcrumb page={{ title: 'Browse', slug: 'browse', __typename: CONTENTFUL_GRAPHQL_TYPES.GUIDELINE, userSection: SECTIONS.GUIDELINES.key }} />
                <main>
                    <SearchSidebar selectedProvider={serviceProvider} />
                    <div className="guideline content wrapper">
                        {serviceProvider && (
                            <div className="row wrap provider-header">
                                <div className="col-sm-12 col-md-8 order-md-1 order-2 provider-info">
                                    <h1>{serviceProvider.name}</h1>
                                    <p>{serviceProvider.description.description}</p>
                                </div>
                                <div className="col-sm-12 col-md-4 order-md-2 order-1 provider-logo">
                                    <img
                                        className="embedded-responsive"
                                        src={serviceProvider.logo.file.url}
                                        alt={serviceProvider.name}
                                        title={serviceProvider.title}
                                    />
                                </div>
                            </div>
                        )}
                        <BrowseFilters provider={serviceProvider || undefined} />
                        <AZFilterList
                            items={guidelines}
                            itemKey="title"
                            showTypeFilter
                            selectedServiceProvider={serviceProvider ? serviceProvider.slug : null}
                            selectedType={type}
                            selectedCategory={category}
                            selectedIndex={index}
                            onItemHover={(elt) => this.onItemHovered(elt, true)}
                            onItemLeave={(elt) => this.onItemHovered(elt, false)}
                            renderComponent={item => {
                                let url = buildURL(item, item.guidelineType && item.guidelineType.guidelineType === 'Calculator' ? CONTENTFUL_GRAPHQL_TYPES.CALCULATOR : CONTENTFUL_GRAPHQL_TYPES.GUIDELINE)
                                if (item.searchRedirectUrl) {
                                    url = item.searchRedirectUrl
                                    return <div className="index-item">
                                        <a href={url} target="_blank" rel="noopener noreferrer">{item.title}</a>
                                        { item.isHovered || item.isBookmarked ? <span className="shortlist-star" onClick={() => this.onBookmark(item)} onMouseEnter={() => this.onStarHovered(item, true)} onMouseLeave={() => this.onStarHovered(item, false)}>
                                             <IconStar fill={item.isStarHovered || item.isBookmarked ? '#485C97' : '#fff' } />
                                        </span> : <></> }
                                    </div>
                                }
                                return <div className="index-item">
                                    <Link to={url}>{item.title}</Link>
                                    {item.isHovered || item.isBookmarked ? <span className="shortlist-star" onClick={() => this.onBookmark(item)}  onMouseEnter={() => this.onStarHovered(item, true)} onMouseLeave={() => this.onStarHovered(item, false)}>
                                        <IconStar fill={item.isStarHovered || item.isBookmarked ? '#485C97' : '#fff' } />
                                    </span> : <></>}
                                </div>
                            }}
                        />
                    </div>
                </main>
            </PageLayout>
        )
    }
}

export const pageQuery = graphql`
    query GuidelineIndexQuery {
        allContentfulPageGuideline(sort: { fields: [title], order: ASC }) {
            edges {
                node {
                    title
                    slug
                    searchRedirectUrl
                    serviceProvider {
                        slug
                    }
                    guidelineType{
                        guidelineType
                    }
                    categories {
                        name
                        colour
                    }
                }
            }
        }
        allContentfulTypeGuidelineProvider {
            edges {
                node {
                    name
                    slug
                    colour
                    description {
                        description
                    }
                    logo {
                        title
                        file {
                            url
                        }
                    }
                }
            }
        }
        allContentfulWidgetCalculator {
            edges {
                node {
                    slug
                    name
                    searchable
                    categories {
                        name
                        colour
                    }
                }
            }
        }
        allContentfulWidgetSitemap {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
