import React from 'react'
import { ServiceProviderList } from '../common'

export default props => {
    return (
        <aside className={'sidebar'}>
            <div className={'sidebar-box'}>
                <h5>Service Providers</h5>
                <ServiceProviderList {...props} />
            </div>
        </aside>
    )
}
