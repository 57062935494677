import * as React from "react"
const IconStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#485C97"
      strokeWidth={1.5}
      d="m9.222.887.001.002 2.01 4.135.173.356.392.058 4.489.662c.093.014.171.08.202.172a.249.249 0 0 1-.062.253L13.17 9.747l-.277.274.065.385.772 4.55v.001a.255.255 0 0 1-.105.248.25.25 0 0 1-.265.015l-4.006-2.139-.353-.188-.353.188-4.007 2.14a.253.253 0 0 1-.368-.264l.767-4.552.065-.384-.277-.274-3.256-3.222a.255.255 0 0 1-.06-.259.248.248 0 0 1 .2-.166l4.486-.662.392-.058.173-.356L8.771.892a.251.251 0 0 1 .451-.005Z"
    />
  </svg>
)
export default IconStar
